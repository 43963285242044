import FusionCharts from 'fusioncharts';
import ReactFC from 'react-fusioncharts';
import MSColumn2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

const StackedColumnChart = (props) => {
  function setAxisName() {
    let xAxisName = props?.xAxisName;
    if (props?.separator) {
      xAxisName = xAxisName + ' (' + props?.separator + ')';
    }
    return xAxisName;
  }

  const chartConfigs = {
    type: props?.type,
    renderAt: 'burnoutMale',
    width: '100%',
    height: props?.height,
    dataFormat: 'JSON',
    dataSource: {
      chart: {
        labelDisplay:
          props?.xAxisName !== 'Age Group'
            ? props?.categories?.length > 5
              ? 'rotate'
              : ''
            : '',
        slantLabel:
          props?.xAxisName !== 'Age Group'
            ? props?.categories?.length > 5
              ? '1'
              : ''
            : '',
        maxLabelHeight: '77',
        numVisiblePlot: '12',
        flatscrollbars: '0',
        scrollheight: '12',
        bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
        showValues: '1',
        valueFontSize: '12',
        valueFontColor: '#ffffff',
        placeValuesInside: '1',
        numberSuffix: '%',
        xAxisName: setAxisName(),
        yAxisName: props?.yAxisName,
        yAxisMinValue: '0',
        yAxisMaxValue: '100',
        minPlotHeightForValue: '15',
        theme: 'fusion',
      },
      categories: [
        {
          category: props?.categories,
        },
      ],
      dataset: props?.data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default StackedColumnChart;

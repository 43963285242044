import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import MSColumn2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

const MSColumn2dGraph = (props) => {
  const chartConfigs = {
    type: props?.type,
    renderAt: 'chart-container3',
    width: '100%',
    height: props?.height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        showLegend: '1',
        bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
        showValues: '1',
        valueFontColor: '#ffffff',
        placeValuesInside: '1',
        valueFontSize: '12',
        numberSuffix: '%',
        xAxisName: props?.xAxisName,
        yAxisName: props?.yAxisName,
        yAxisMaxValue: '100',
        palettecolors: '#00A39D',
        plotPaddingPercent: '20',
        theme: 'fusion',
      },
      categories: [
        {
          category: props?.data?.categories,
        },
      ],
      dataset: props?.data?.dataset,
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default MSColumn2dGraph;

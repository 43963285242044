import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import MSColumn2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

const BarChart = (props) => {
  const chartConfigs = {
    type: 'MSColumn2D',
    width: '100%',
    height: '300',
    dataFormat: 'json',
    dataSource: {
      chart: {
        showLegend: '0',
        showYAxisValues: '0',
        showValues: '1',
        valueFontColor: '#ffffff',
        placeValuesInside: '1',
        valueFontSize: '13',
        numberSuffix: '%',
        yAxisName:
          'Low&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;High',
        palettecolors: '#00A39D',
        plotPaddingPercent: '20',
        theme: 'fusion',
      },
      categories: [
        {
          category: props.labels,
        },
      ],
      dataset: props.dataset,
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default BarChart;

import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Area2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, Area2D, FusionTheme);

const AreaChart = (props) => {
  const chartConfigs = {
    type: props?.type,
    renderAt: 'chart-enthusiasm',
    width: '100%',
    height: '350',
    dataFormat: 'json',
    dataSource: {
      chart: {
        numberSuffix: '%',
        yAxisMaxValue: '100',
        showPercentValues: 1,
        showTooltip: 1,
        showPercentInTooltip: 1,
        decimals: '1',
        plotGradientColor: props?.plotGradientColor,
        usePlotGradientColor: '1',
        palettecolors: props?.palettecolors,
        plotFillAngle: '90',
        canvasPadding: '30',
        anchorRadius: 6,
        anchorBorderThickness: 3,
        anchorBorderColor: props?.palettecolors,
        theme: 'fusion',
      },
      // data
      data: props?.data,

      trendlines: [
        {
          line: [props?.trendlines],
        },
      ],
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default AreaChart;

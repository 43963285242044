import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import MSColumn2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

const Column2dGraph = (props) => {
  const chartConfigs = {
    type: props?.type,
    width: '100%',
    height: props?.height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        labelDisplay: props?.data?.data?.length > 5 ? 'rotate' : '',
        slantLabel: '1',
        maxLabelHeight: '77',
        showValues: '1',
        valueFontColor: '#ffffff',
        placeValuesInside: '1',
        valueFontSize: '13',
        bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
        numberSuffix: '%',
        theme: 'fusion',
        xAxisName: props?.data?.xAxisName,
        yAxisName: props?.data?.yAxisName,
        palettecolors: '#00A39D',
      },
      data: props?.data?.data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default Column2dGraph;

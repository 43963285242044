import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import msline from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, msline, FusionTheme);

const LineChart = (props) => {
  const chartConfigs = {
    type: 'msline',
    width: '100%',
    height: '300',
    dataFormat: 'json',
    containerBackgroundOpacity: 0,
    dataSource: {
      chart: {
        showYAxisValues: '0',
        showLegend: '1',
        showValues: '0',
        yAxisMaxValue: '100',
        yAxisName:
          'Low&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;High',

        theme: 'fusion',
        bgAlpha: 20,
      },
      categories: [
        {
          category: props.category,
        },
      ],
      dataset: [
        {
          seriesname: '',
          color: '#00a39d',
          data: props.dataset1,
        },
      ],
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default LineChart;

import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import MSColumn2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

const Pie2dGraph = (props) => {
  const chartConfigs = {
    type: props?.type,
    renderAt: 'burnoutManagers_pie',
    width: '100%',
    height: props?.height,
    dataFormat: 'json',
    dataSource: {
      chart: {
        bgColor: props?.bgColor ? props?.bgColor : '#ffffff',
        slicingDistance: '10',
        numbersuffix: '%',
        showPercentValues: '0',
        showPercentInTooltip: '0',
        decimals: '2',
        useDataPlotColorForLabels: '1',
        //Theme
        theme: 'fusion',
      },
      // data
      data: props?.data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default Pie2dGraph;

import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import MSColumn2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, MSColumn2D, FusionTheme);

const DoughnutGraph = (props) => {
  const chartConfigs = {
    type: props?.type,
    renderAt: 'feedback-doughnut',
    width: '100%',
    height: '350',
    dataFormat: 'json',
    dataSource: {
      chart: {
        bgColor: '#ffffff',
        pieRadius: '100',
        doughnutRadius: '70',
        slicingDistance: '10',
        numbersuffix: '%',
        showPercentValues: '0',
        defaultCenterLabel: 'Employee Feedback',
        centerLabelBold: '1',
        showTooltip: '0',
        showPercentInTooltip: '0',
        centerLabel: '$label: $value',
        decimals: '2',
        useDataPlotColorForLabels: '1',
        //Theme
        theme: 'fusion',
      },
      // data
      data: props?.data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default DoughnutGraph;
